body {
  word-break: break-word !important;
}
label {
  font-weight: bold !important;
}
.btn-link {
  color: #A2C11C !important;
}
.bg-image {
  background-position: center center;
  background-size: cover;
}
.post-image {
  max-height: 150px;
}
.opposition-list .opposition-element {
  padding: 15px;
  font-weight: bold !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
}
.opposition-list .opposition-element .opposition {
  background: white;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  text-align: center;
  height: 98%;
}
.opposition-list .opposition-element .opposition h3 {
  text-decoration: none !important;
}
.subjects-list .subject .subject-header {
  min-height: 4rem;
}
.subjects-list .subject .subject-header .subject-title {
  position: relative;
  line-height: 4rem;
  width: 100%;
}
.subjects-list .subject .subject-header .subject-title button {
  box-shadow: none !important;
  outline: none !important;
  background: transparent;
  font-size: .8rem;
  color: white !important;
  width: 100%;
  height: 100%;
  text-align: left;
  text-decoration: none !important;
  font-weight: bold;
}
.subjects-list .subject .topic-header {
  border-bottom: 1px solid #ccc;
}
.subjects-list .subject .topic-header .topic-title {
  color: #222;
  font-weight: bold;
  width: 100%;
  padding: 5px;
}
.subjects-list .subject .topic-header .topic-title button {
  box-shadow: none !important;
  background: transparent;
  color: #222;
  font-size: .8rem;
  font-weight: bold;
  width: 100%;
  text-align: left;
}
.subjects-list .subject .question-indicator {
  background: #444;
  color: #ffffff;
}
@media (min-width: 767px) {
  .subjects-list .subject .subject-header .subject-title button {
    font-size: 1.5rem;
  }
  .subjects-list .subject .topic-header .topic-title button {
    font-size: 1.2rem;
  }
}
/**
 * Circle Styles
 */
.circle {
  position: relative;
  display: block;
  margin: 2em 0;
  background-color: transparent;
  color: #222;
  text-align: center;
  max-width: 35px;
}
.circle:after {
  display: block;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #ddd;
  content: "";
}
.circle__inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.circle__wrapper {
  display: table;
  width: 100%;
  height: 100%;
}
.circle__content {
  display: table-cell;
  /* padding: 1em;*/
  vertical-align: middle;
}
@media (min-width: 480px) {
  .circle__content {
    font-size: 1em;
  }
}
@media (max-width: 768px) {
  .display-4 {
    font-size: 2.5rem;
  }
  .subjects-list .subject .subject-header .subject-title {
    font-size: .8rem !important;
  }
}
@media (min-width: 768px) {
  .circle__content {
    font-size: 1em;
  }
}
.exam {
  font-family: Roboto, monospace, sans-serif !important;
}
.exam h1,
.exam h2,
.exam h3,
.exam h4,
.exam h5,
.exam h6 {
  font-family: Roboto, monospace, sans-serif !important;
}
.exam .question-line {
  border-bottom: 2px solid #222;
}
.exam .question-indicator {
  color: white !important;
  font-weight: bold;
}
.exam .question-text {
  font-weight: bold;
  padding: 2rem 0;
  display: -webkit-box;
  display: flex;
}
.exam .question-text .text {
  font-size: 15px !important;
  font-family: Lato, Helvetica, Arial, sans-serif !important;
  font-weight: bolder;
}
@media (min-width: 768px) {
  .exam .question-text .text {
    font-size: 33px !important;
  }
}
.exam .list-group .list-group-item {
  border: none;
}
.exam .list-group .list-group-item .custom-control {
  border-radius: 5px;
  position: relative;
  display: block;
  min-height: 2.5rem;
  line-height: 2.5rem;
  padding-left: 0;
}
@media (min-width: 768px) {
  .exam .list-group .list-group-item .custom-control {
    min-height: 3.5rem;
    line-height: 3.5rem;
  }
}
.exam .list-group .list-group-item .custom-control label {
  padding: 5px 15px;
  width: 100%;
  font-size: 15px;
  line-height: 1rem;
  font-weight: normal !important;
}
@media (min-width: 768px) {
  .exam .list-group .list-group-item .custom-control label {
    padding: 15px 15px;
    font-size: 24px;
    line-height: 1.5rem;
  }
}
.exam .list-group .list-group-item .custom-control.selected {
  background: #ffc107;
}
.exam .list-group .list-group-item .custom-control.right {
  background: #96bf1d !important;
  color: white;
}
.exam .list-group .list-group-item .custom-control.wrong {
  background: #da4242  !important;
  color: white;
}
.exam .list-group .list-group-item .custom-control:hover:not(.selected):not(.right):not(wrong) {
  background: #aaaaaa;
}
.exam .exam-info {
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-radius: 5px;
}
.exam .exam-info .exam-subject-title {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
  display: block;
}
.exam .exam-info .exam-topic-title {
  font-size: 1rem;
  display: block;
}
.exam .exam-info .exam-title {
  font-size: 1rem;
}
.exam .exam-answers {
  color: #222 !important;
}
.exam .exam-answers .custom-control-label {
  width: 100%;
  line-height: 1rem;
  font-weight: bold;
}
.exam .answers-pagination .page-item {
  border-radius: 50%;
}
.exam .navigation-buttons {
  cursor: pointer;
  display: block;
}
.exam .navigation-buttons:hover {
  font-weight: bold;
  background: #aaa;
}
.exam .navigation-buttons:hover i {
  color: white !important;
}
.exam .counter {
  color: #222;
  padding: 0.5rem;
  border-radius: 5px;
  background: white;
}
.exam .counter .counter-title {
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.exam .counter .counter-numbers {
  font-family: Roboto, monospace, sans-serif;
}
.exam .counter .counter-mode.info {
  background-color: #17a2b8;
}
.exam .counter .counter-mode.danger {
  background-color: #da4242;
}
.exam .counter .counter-mode.warning {
  background-color: #ffc107;
}
.container .content {
  padding: 25px 0;
}
.page-link {
  color: #A2C11C;
}
.page-link:hover {
  color: #b8dc20;
}
.page-item.active .page-link {
  background-color: #A2C11C;
  border-color: #A2C11C;
  color: #222;
}
#questions-indicators a {
  padding: 0;
  border-radius: 50%;
  border: 1px solid #444;
  font-size: 15px;
  color: #444 !important;
  margin: 2px 2px;
  width: 35px;
  height: 35px;
  line-height: 35px;
}
#questions-indicators a.active {
  background-color: #444 !important;
  color: white !important;
}
#questions-indicators a.blank {
  background: #ffc107;
  border-color: #ffc107;
}
#questions-indicators a.right {
  background: #96bf1d;
  border-color: #96bf1d;
  color: white !important;
}
#questions-indicators a.wrong {
  background: #da4242;
  border-color: #da4242;
  color: white !important;
}
.resume-indicators {
  font-size: 1.25rem;
}
.list-group .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #222;
}
.answers_blank {
  color: #ffc107;
  font-size: 5rem;
}
.answers_blank span {
  font-family: Roboto, monospace, sans-serif;
}
.answers_right {
  color: #96bf1d;
  font-size: 5rem;
}
.answers_right span {
  font-family: Roboto, monospace, sans-serif;
}
.answers_wrong {
  color: #da4242;
  font-size: 5rem;
}
.answers_wrong span {
  font-family: Roboto, monospace, sans-serif;
}
/*
 * Component: Direct Chat
 * ----------------------
 */
.user-threads {
  overflow-x: hidden;
  max-height: 50vh;
  overflow: auto;
}
.thread {
  cursor: pointer;
}
.thread.active {
  background-color: #A2C11C !important;
}
.direct-chat .box-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  overflow-x: hidden;
  padding: 0;
}
.direct-chat.chat-pane-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 65vh;
  overflow: auto;
}
.direct-chat-msg,
.direct-chat-text {
  display: block;
}
.direct-chat-msg {
  margin-bottom: 10px;
}
.direct-chat-msg:before,
.direct-chat-msg:after {
  content: " ";
  display: table;
}
.direct-chat-msg:after {
  clear: both;
}
.direct-chat-messages,
.direct-chat-contacts {
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.direct-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #d2d6de;
  border: 1px solid #d2d6de;
  margin: 5px 0 0 50px;
  color: #444;
}
.direct-chat-text:after,
.direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
}
.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}
.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}
.right .direct-chat-text {
  margin-right: 50px;
  margin-left: 0;
}
.right .direct-chat-text:after,
.right .direct-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #d2d6de;
}
.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px;
}
.right .direct-chat-img {
  float: right;
}
.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
}
.direct-chat-name {
  font-weight: 600;
}
.direct-chat-timestamp {
  color: #999;
}
.direct-chat-contacts-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.direct-chat-contacts {
  -webkit-transform: translate(101%, 0);
  transform: translate(101%, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
  background: #222d32;
  color: #fff;
  overflow: auto;
}
.contacts-list > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 0;
}
.contacts-list > li:before,
.contacts-list > li:after {
  content: " ";
  display: table;
}
.contacts-list > li:after {
  clear: both;
}
.contacts-list > li:last-of-type {
  border-bottom: none;
}
.contacts-list-img {
  border-radius: 50%;
  width: 40px;
  float: left;
}
.contacts-list-info {
  margin-left: 45px;
  color: #fff;
}
.contacts-list-name,
.contacts-list-status {
  display: block;
}
.contacts-list-name {
  font-weight: 600;
}
.contacts-list-status {
  font-size: 12px;
}
.contacts-list-date {
  color: #aaa;
  font-weight: normal;
}
.contacts-list-msg {
  color: #999;
}
.direct-chat-danger .right > .direct-chat-text {
  background: #dd4b39;
  border-color: #dd4b39;
  color: #fff;
}
.direct-chat-danger .right > .direct-chat-text:after,
.direct-chat-danger .right > .direct-chat-text:before {
  border-left-color: #dd4b39;
}
.direct-chat-primary .right > .direct-chat-text {
  background: #3c8dbc;
  border-color: #3c8dbc;
  color: #fff;
}
.direct-chat-primary .right > .direct-chat-text:after,
.direct-chat-primary .right > .direct-chat-text:before {
  border-left-color: #3c8dbc;
}
.direct-chat-warning .right > .direct-chat-text {
  background: #f39c12;
  border-color: #f39c12;
  color: #fff;
}
.direct-chat-warning .right > .direct-chat-text:after,
.direct-chat-warning .right > .direct-chat-text:before {
  border-left-color: #f39c12;
}
.direct-chat-info .right > .direct-chat-text {
  background: #00c0ef;
  border-color: #00c0ef;
  color: #fff;
}
.direct-chat-info .right > .direct-chat-text:after,
.direct-chat-info .right > .direct-chat-text:before {
  border-left-color: #00c0ef;
}
.direct-chat-success .right > .direct-chat-text {
  background: #A2C11C;
  border-color: #A2C11C;
  color: #fff;
}
.direct-chat-success .right > .direct-chat-text:after,
.direct-chat-success .right > .direct-chat-text:before {
  border-left-color: #A2C11C;
}
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.product-card {
  overflow: hidden;
}
.product-card .product-icon {
  position: absolute;
  right: -35px;
  opacity: 0.08;
  font-size: 11rem;
  bottom: -25px;
  z-index: 0;
}
.product-card .badge-deal {
  position: absolute;
  right: -30px;
  top: 20px;
  padding: 5px 40px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  overflow: hidden;
}
.product-card button {
  position: relative;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

/*# sourceMappingURL=cdo.css.map */
