html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Muli', 'Helvetica', 'Arial', 'sans-serif';
}

a {
  color: #A2C11C;
  transition: all .35s;
}

a:hover, a:focus {
  color: #7d9416;
}

hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
  border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

section {
  padding: 100px 0;
}

section h2 {
  font-size: 50px;
}

#mainNav {
  border-color: rgba(34, 34, 34, 0.05);
  background-color: white;
  transition: all .35s;
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}

#mainNav .navbar-brand {
  color: black;
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}

#mainNav .navbar-brand:hover, #mainNav .navbar-brand:focus {
  color: #7d9416;
}

#mainNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #A2C11C;
}

#mainNav .navbar-nav > li > a {
  font-size: 0.75rem;
  font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
  letter-spacing: 2px;
  text-transform: uppercase;
}

#mainNav .navbar-nav > li > a.active {
  color: #A2C11C !important;
  background-color: transparent;
}

#mainNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#mainNav .navbar-nav > li > a,
#mainNav .navbar-nav > li > a:focus {
  color: #A2C11C;
}

#mainNav .navbar-nav > li > a:hover,
#mainNav .navbar-nav > li > a:focus:hover {
  color: #A2C11C;
}

@media (min-width: 992px) {
  #mainNav {
    border-color: transparent;
    background-color: transparent;
  }
  #mainNav .navbar-brand {
    color: white;
    text-shadow: 1px 1px 1px #222222;
  }
  #mainNav .navbar-brand:hover, #mainNav .navbar-brand:focus {
    color: white;
    font-weight: bold;
  }
  #mainNav .navbar-nav > li > a,
  #mainNav .navbar-nav > li > a:focus {
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-nav > li > a:hover,
  #mainNav .navbar-nav > li > a:focus:hover {
    color: white;
    font-weight: bold;
  }
  #mainNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    background-color: white;
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #222222;
  }
  #mainNav.navbar-shrink .navbar-brand:hover, #mainNav.navbar-shrink .navbar-brand:focus {
    color: #A2C11C;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #222222;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a:hover,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #A2C11C;
  }
}

header.masthead {
  position: relative;
  padding-top: 0;
  width: 100%;
  color: white;
  background: url("/img/bg-pattern.png"), #7B950D;
  background: url("/img/bg-pattern.png"), linear-gradient(to left, #7B950D, #484848);
}

header.masthead .header-content {
  max-width: 500px;
  margin-bottom: 100px;
  text-align: center;
}

header.masthead .header-content h1 {
  font-size: 30px;
}

header.masthead .device-container {
  max-width: 325px;
  margin-right: auto;
  margin-left: auto;
}

header.masthead .device-container .screen img {
  border-radius: 3px;
}

header.masthead .container, header.masthead .container-fluid {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 775px;
  }
  header.masthead .header-content {
    margin-bottom: 0;
    text-align: left;
  }
  header.masthead .header-content h1 {
    font-size: 50px;
  }
  header.masthead .device-container {
    max-width: 325px;
  }
  header.masthead .container, header.masthead .container-fluid {
    padding-top: 0;
    padding-bottom: 0;
  }
}

section.download {
  position: relative;
  padding: 150px 0;
}

section.download h2 {
  font-size: 50px;
  margin-top: 0;
}

section.download .badges .badge-link {
  display: block;
  margin-bottom: 25px;
}

section.download .badges .badge-link:last-child {
  margin-bottom: 0;
}

section.download .badges .badge-link img {
  height: 60px;
}

@media (min-width: 768px) {
  section.download .badges .badge-link {
    display: inline-block;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  section.download h2 {
    font-size: 70px;
  }
}

section.features .section-heading {
  margin-bottom: 100px;
}

section.features .section-heading h2 {
  margin-top: 0;
}

section.features .section-heading p {
  margin-bottom: 0;
}

section.features .device-container,
section.features .feature-item {
  max-width: 325px;
  margin: 0 auto;
}

section.features .device-container {
  margin-bottom: 100px;
}

@media (min-width: 992px) {
  section.features .device-container {
    margin-bottom: 0;
  }
}

section.features .feature-item {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

section.features .feature-item h3 {
  font-size: 30px;
}

section.features .feature-item i {
  font-size: 80px;
  display: block;
  margin-bottom: 15px;
  background: linear-gradient(to left, #7B950D, #484848);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

section.cta {
  position: relative;
  padding: 250px 0;
  background-image: url("/img/header-bg.jpg");
  background-position: center;
  background-size: cover;
}

section.cta .cta-content {
  position: relative;
  z-index: 1;
}

section.cta .cta-content h2 {
  font-size: 50px;
  max-width: 450px;
  margin-top: 0;
  margin-bottom: 25px;
  color: white;
}

@media (min-width: 768px) {
  section.cta .cta-content h2 {
    font-size: 80px;
  }
}

section.cta .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.social-links {
  text-align: center;
}

.social-links h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

.social-links h2 i {
  color: #dd4b39;
}

.social-links ul.list-social {
  margin-bottom: 0;
}

.social-links ul.list-social li a {
  font-size: 40px;
  line-height: 80px;
  display: block;
  width: 80px;
  height: 80px;
  color: white;
  border-radius: 100%;
}

.social-links ul.list-social li.social-twitter a {
  background-color: #1da1f2;
}

.social-links ul.list-social li.social-twitter a:hover {
  background-color: #0d95e8;
}

.social-links ul.list-social li.social-facebook a {
  background-color: #3b5998;
}

.social-links ul.list-social li.social-facebook a:hover {
  background-color: #344e86;
}

.social-links ul.list-social li.social-google-plus a {
  background-color: #dd4b39;
}

.social-links ul.list-social li.social-google-plus a:hover {
  background-color: #d73925;
}

.social-links ul.list-social li.social-instagram a {
  background-color: #c13584;
}

.social-links ul.list-social li.social-instagram a:hover {
  background-color: #ad3076;
}

footer {
  padding: 25px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222222;
}

footer p {
  font-size: 12px;
  margin: 0;
}

footer ul {
  margin-bottom: 0;
}

footer ul li a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}

footer ul li a:hover, footer ul li a:focus, footer ul li a:active, footer ul li a.active {
  text-decoration: none;
}

body {
  background-color: #f7f7f7;
}

.bg-primary {
  background: #A2C11C;
  background: linear-gradient(#A2C11C, #8fab19);
}

.text-primary {
  color: #A2C11C;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.btn-outline {
  color: white;
  border: 1px solid;
  border-color: white;
}

.btn-outline:hover, .btn-outline:focus, .btn-outline:active, .btn-outline.active {
  color: white;
  border-color: #A2C11C;
  background-color: #A2C11C;
}

.btn {
  border-radius: 300px;
  font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn-xl {
  font-size: 11px;
  padding: 15px 45px;
}

.display-3 {
  font-size: 3.5rem;
}

@media (min-width: 768px) {
  .display-3 {
    font-size: 4.5rem;
  }
}

/*# sourceMappingURL=new-age.css.map */
